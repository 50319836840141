import React from 'react';
import './Services.css';

function Services() {
  return (
    <section id="services" className="services-section">
      <div className="container">
        <h2 className="section-title">Serviciile Noastre</h2>
        <div className="services-grid">
          <div className="service-category">
            <h3>Construcții Civile</h3>
            <ul>
              <li>Case, vile la roșu sau la cheie</li>
              <li>Clădiri, magazine, sedii, restaurante</li>
              <li>Lucrări de pământuri și fundații</li>
              <li>Zidării din cărămidă și piatră</li>
              <li>Șarpante din lemn și metal</li>
              <li>Structuri Rezistență</li>
              <li>Garduri Beton / Lemn</li>
              <li>Fose Septice</li>
              <li>Instalații termice și sanitare</li>
              <li>Lucrări Instalații Electrice</li>
              <li>Hidroizolații</li>
              <li>Termoizolații</li>
              <li>Scări drepte / spiralate</li>
            </ul>
          </div>

          <div className="service-category">
            <h3>Construcții Industriale</h3>
            <ul>
              <li>Hale metalice la cheie</li>
              <li>Terasamente</li>
              <li>Infrastructură</li>
              <li>Închideri Termoizolante</li>
              <li>Pardoseli Industriale</li>
              <li>Sistematizări Verticale</li>
              <li>Confecții și structuri metalice</li>
              <li>Ventilații</li>
              <li>Demolări</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;