import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Projects.css';

function Projects() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [direction, setDirection] = useState('next');
  const [isAnimating, setIsAnimating] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const projects = [
    {
      title: "Reparatii capitale la invelitoarea Casa Parinteasca, M.Eminescu Ipotesti",
      location: "Com.Mihai Eminescu, Jud.Botosani",
      images: [
        process.env.PUBLIC_URL + '/images/poza1.png',
        process.env.PUBLIC_URL + '/images/poza2.png',
        process.env.PUBLIC_URL + '/images/poza3.png',
        process.env.PUBLIC_URL + '/images/poza4.png'
      ]
    },
    {
      title: "Casa taraneasca Comuna Vorona Botosani",
      images: [process.env.PUBLIC_URL + '/images/poza5.png']
    },
    {
      title: "Bisericuta de familie - Centrul National de studii M.Eminescu Ipotesti",
      images: [process.env.PUBLIC_URL + '/images/poza6.png']
    },
    {
      title: "Inspectoratul Scolar Botosani",
      images: [process.env.PUBLIC_URL + '/images/poza7.png']
    },
    {
      title: "Bisericuta de familie - Centrul National de studii M.Eminescu Ipotesti",
      images: [process.env.PUBLIC_URL + '/images/poza8.png']
    }
  ];

  const handleImageTransition = (newIndex) => {
    if (isAnimating) return;
    
    setIsAnimating(true);
    const isNext = newIndex > currentImageIndex;
    setDirection(isNext ? 'next' : 'prev');
    setCurrentImageIndex(newIndex);
    
    setTimeout(() => {
      setIsAnimating(false);
    }, 500); // Match this with your CSS transition duration
  };

  const nextImage = () => {
    const newIndex = currentImageIndex === projects[0].images.length - 1 ? 0 : currentImageIndex + 1;
    handleImageTransition(newIndex);
  };

  const prevImage = () => {
    const newIndex = currentImageIndex === 0 ? projects[0].images.length - 1 : currentImageIndex - 1;
    handleImageTransition(newIndex);
  };

  const getImageClassName = (index) => {
    if (index === currentImageIndex) return 'carousel-image active';
    if (direction === 'next') {
      return index < currentImageIndex ? 'carousel-image prev' : 'carousel-image next';
    } else {
      return index > currentImageIndex ? 'carousel-image next' : 'carousel-image prev';
    }
  };

  const openProject = (project) => {
    setSelectedProject(project);
    setCurrentImageIndex(0);
  };

  return (
    <section id="projects" className="projects-section">
      <div className="container">
        <h2 className="section-title">Proiecte Realizate</h2>
        
        {/* Featured Project - Now smaller */}
        <div className="featured-project">
          <div className="project-card large">
            <div className="carousel-container">
              {projects[0].images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className={getImageClassName(index)}
                />
              ))}
              
              {/* Text Overlay */}
              <div className="project-text-overlay">
                <h2>{projects[0].title}</h2>
                <p>{projects[0].location}</p>
              </div>

              {/* Controls and indicators */}
              <div className="carousel-controls">
                <button 
                  className="carousel-btn prev" 
                  onClick={(e) => {
                    e.stopPropagation();
                    prevImage();
                  }}
                  disabled={isAnimating}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button 
                  className="carousel-btn next" 
                  onClick={(e) => {
                    e.stopPropagation();
                    nextImage();
                  }}
                  disabled={isAnimating}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
              
              <div className="carousel-indicators">
                {projects[0].images.map((_, index) => (
                  <span
                    key={index}
                    className={`indicator ${index === currentImageIndex ? 'active' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageTransition(index);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Enhanced Projects Grid */}
        <div className="projects-grid">
          {projects.slice(1).map((project, index) => (
            <div 
              key={index} 
              className="project-card"
              onClick={() => openProject(project)}
            >
              <div className="project-image-container">
                <img 
                  src={project.images[0]} 
                  alt={project.title} 
                  className="project-image"
                />
              </div>
              <div className="project-overlay">
                <h3 className="project-title">{project.title}</h3>
                {project.location && (
                  <p className="project-location">{project.location}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;