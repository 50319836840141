import React from 'react';
import './About.css';

function About() {
  return (
    <section id="about" className="about-section">
      <div className="container">
        <div className="about-grid glass-card">
          <div className="about-content">
            <h2 className="section-title">Despre ALERO</h2>
            <p>
              Cu o experiență de peste 25 de ani în domeniul construcțiilor, 
              ALERO s-a impus ca unul dintre liderii pieței din România. 
              Specializată în execuția de construcții civile și industriale, 
              compania noastră oferă servicii complete, de la proiectare până 
              la execuție și finisare.
            </p>
          </div>
          <div className="about-image">
            <img src="/images/pozahome2.png" alt="ALERO Construction" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;