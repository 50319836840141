import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import Projects from './components/Projects';
import Contact from './components/Contact';
import About from './components/About';
import Footer from './components/Footer';
import FixedButtons from './components/FixedButtons';
import ChatSystem from './components/ChatSystem';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <About />
      <Services />

      <Projects />
      <Contact />
      
      <Footer />
      <FixedButtons />
      <ChatSystem />
    </div>
  );
}

export default App;