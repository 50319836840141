import React, { useState } from 'react';
import './Navbar.css';
// Keep LOGO.png in public/images/LOGO.png
const logo = process.env.PUBLIC_URL + '/images/LOGO.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  // Function to handle link clicks
  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="container navbar-container">
        <a href="#" className="navbar-brand">
          <img src={logo} alt="ALERO" className="navbar-logo" />
        </a>
        <button 
          className={`mobile-menu-btn ${isOpen ? 'active' : ''}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className={`nav-links ${isOpen ? 'active' : ''}`}>
          <a href="#about" onClick={handleLinkClick}>Despre Noi</a>
          <a href="#services" onClick={handleLinkClick}>Servicii</a>
          <a href="#projects" onClick={handleLinkClick}>Proiecte</a>
          <a href="#contact" onClick={handleLinkClick}>Contact</a>
          <a 
            href="https://dashboard.aipro.ro/start/11275970" 
            className="login-btn"
            onClick={handleLinkClick}
          >
            LOGIN
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;