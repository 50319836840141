import React from 'react';
import './FixedButtons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function FixedButtons() {
  return (
    <div className="fixed-contact-buttons">
      <a
        href="https://wa.me/40744556520"
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Contact on WhatsApp"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <a 
        href="tel:+40744556520" 
        className="phone-button"
        aria-label="Call us"
      >
        <FontAwesomeIcon icon={faPhone} />
      </a>
    </div>
  );
}

export default FixedButtons;