import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="hero-background"></div>
      <div className="container">
        <div className="hero-content">
          <div className="hero-text-container">
            <h1 className="hero-title">
              <span className="title-animation">ALERO</span>
            </h1>
            <div className="hero-description">
              <h2 className="main-text">
                Executăm construcţii civile şi industriale
                <br />
                cu expertiză de peste 25 de ani
              </h2>
              <p className="sub-text">
                Oferim servicii complete de la proiectare până la 
                execuție și finisare.
              </p>
              <a href="#projects" className="cta-button">Descoperă Proiectele Noastre</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;