import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

import euro1 from "../assets/euro1.png";
import euro2 from "../assets/euro2.png";
import euro3 from "../assets/euro3.png";
import euro4 from "../assets/euro4.png";
import pdfFile from "../assets/DIGITALIZARE ALERO.pdf";

function Footer() {
  const currentYear = new Date().getFullYear();

  const handleInfoClick = () => {
    window.open(pdfFile, "_blank"); // Am adăugat '_blank' pentru a deschide într-o filă nouă
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-section">
            <h3>ALERO</h3>
            <p>Excelență în construcții din 1998</p>
          </div>
          
          <div className="footer-section">
            <h4>Contact Rapid</h4>
            <p>Email: alero_bt@yahoo.com</p>
            <p>Tel:0744 556 520 </p>
            <p>Calea Nationala, Nr. 21, Botoșani, Județ Botoșani</p>
          </div>

          <div className="footer-section">
            <h4>Social Media</h4>
            <div className="social-links">
              <a href="https://www.facebook.com/profile.php?id=61569693745258" target="https://www.facebook.com/profile.php?id=61569693745258" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href="https://www.linkedin.com/in/alero-constructii-b53b7133a/" target="https://www.linkedin.com/in/alero-constructii-b53b7133a/" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>
        </div>

        <div className="web-links">
          <div className="url-links">
            <a href="http://www.regionordest.ro" className="web-link">
              www.regionordest.ro
            </a>
            <a
              href="https://www.facebook.com/Regio.NordEst.ro"
              className="web-link"
            >
              www.facebook.com/Regio.NordEst.ro
            </a>
          </div>
          <div className="aproape-button">Aproape de tine</div>
          
        </div>

          <div className="footer-banner">
            <div className="logo-row">
              <img src={euro1} alt="Euro Logo 1" className="banner-logo" />
              <img src={euro2} alt="Euro Logo 2" className="banner-logo" />
              <img src={euro3} alt="Euro Logo 3" className="banner-logo" />
              <img src={euro4} alt="Euro Logo 4" className="banner-logo" />
            </div>
            <button onClick={handleInfoClick} className="info-button">
              Proiect digitalizare - Află mai multe
            </button>
          </div>
        
        <div className="footer-bottom">
          <p>&copy; {currentYear} ALERO. Toate drepturile rezervate.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;