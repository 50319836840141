import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Contact.css';

function Contact() {
  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <div className="contact-grid">
          <div className="contact-info glass-card">
            <h2>Contact</h2>
            <div className="info-item">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <p>Calea Nationala, Nr. 21, Botoșani, Județ Botoșani</p>
            </div>
            <div className="info-item">
              <FontAwesomeIcon icon={faPhone} />
              <div className="contact-details">
                <p><a href="tel:+40744556520">0744 556 520</a></p>
              </div>
            </div>
            <div className="info-item">
              <FontAwesomeIcon icon={faEnvelope} />
              <div className="contact-details">
                <p><a href="mailto:alero_bt@yahoo.com">alero_bt@yahoo.com</a></p>
              </div>
            </div>
          </div>

          <div className="map-container glass-card">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2711.1370412639427!2d26.6296104!3d47.7555176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4734ebfde97fa6cd%3A0x1b231082692a16e5!2sCalea%20Na%C8%9Bional%C4%83%2021%2C%20Boto%C8%99ani!5e0!3m2!1sen!2sro!4v1699209820146!5m2!1sen!2sro"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Company Location"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;